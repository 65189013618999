import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';
import { toast } from 'sonner'

export const create = createAsyncThunk(
    'refundRequest/create',
    async ({ values }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/api/request-refund/csu`, values)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
);


export const fetchAll = createAsyncThunk(
    'refundRequests/fetchAll',
    async (params) => {
        const promise = api.post("/api/refund/search", params)
        const response = await promise;
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'refundRequests/fetchById',
    async ({ id, successCB }, { dispatch }) => {
        try {
            const response = await api.get(`/api/refund-request/details/${id}`)
            successCB && successCB(dispatch, response.data)
            return response.data;
        } catch (error) { }

    }
);



export const fetchBookingDetailsByPnr = createAsyncThunk(
    'refundRequests/fetchBookingDetailsByPnr',
    async (id) => {
        const response = await api.get(`/api/refund-request/booking-details/${id}`)
        return response.data;
    }
);

export const fetchBookingPaymentsByPnr = createAsyncThunk(
    'refundRequests/fetchBookingPaymentsByPnr',
    async (id) => {
        const response = await api.get(`/api/refund-request/booking-payments/${id}`)
        return response.data;
    }
);

export const fetchPassengerFlightsByPnr = createAsyncThunk(
    'refundRequests/fetchPassengerFlightsByPnr',
    async (id) => {
        const response = await api.get(`/api/refund-request/passenger-flights/${id}`)
        return response.data;
    }
);
export const fetchBookingAmountsByPnr = createAsyncThunk(
    'refundRequests/fetchBookingAmountsByPnr',
    async (id) => {
        console.log("id...", id);
        const response = await api.get(`/api/refund-request/booking-amounts/${id}`)
        return response.data;
    }
);


export const closeRequets = createAsyncThunk(
    'refundRequests/closeRequets',
    async (payload) => {
        const response = await api.post(`/api/request-refund/close-requests`, payload)
        return response.data;
    }
);
export const acceptRequets = createAsyncThunk(
    'refundRequests/acceptRequets',
    async (payload) => {
        const response = await api.post(`/api/refund-request/accept-requests`, payload)
        return response.data;
    }
);
export const approvePayments = createAsyncThunk(
    'refundRequests/approvePayments',
    async (payload) => {
        const response = await api.post(`/api/refund-request/approve-payments`, payload)
        return response.data;
    }
);

export const assignRequest = createAsyncThunk(
    'refundRequests/assignRequest',
    async (payload) => {
        const response = await api.post(`/api/refund-request/assign`, payload)
        return response.data;
    }
);

export const addHistory = createAsyncThunk(
    'refundRequests/addHistory',
    async (payload) => {
        const response = await api.post(`/api/refundrequest/addhistory`, payload)
        return response.data;
    }
);
// export const exportToExcel = createAsyncThunk(
//     'refundRequests/exportToExcel',
//     async (payload) => {
//         const response = await api.post(`/api/refund-request/export-to-excel`, payload)
//         return;
//     }
// );

export const exportToExcel = createAsyncThunk(
    'refundRequests/exportToExcel',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.post(`/api/refund-request/export-to-excel`, payload, {
                responseType: 'blob' // Ensure response is treated as a binary file
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Export failed');
        }
    }
);
export const exportToExcelFinance = createAsyncThunk(
    'refundRequests/exportToExcelFinance',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.post(`/api/refund-request/export-to-excel-finance`, payload, {
                responseType: 'blob' // Ensure response is treated as a binary file
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Export failed');
        }
    }
);


export const completeRequest = createAsyncThunk(
    'refundRequests/completeRequest',
    async ({ payload }, { rejectWithValue }) => {
        try {
            var res = await api.post(`/api/refund-request/complete`, payload)
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }

        // return await api.post(`/api/refund-request/complete`, payload)
        //     .then((res) => res.data)
        //     .catch((err) => {
        //         console.log("err", err);
        //         return rejectWithValue(err.response.data)
        //     })
    }
);

export const getDashBoardSummaries = createAsyncThunk(
    'refundRequests/getDashBoardSummaries',
    async (payload) => {
        const response = await api.post(`/api/refund-request/dashboard-summaries`, payload)
        return response.data;
    }
);

const requestsSlice = createSlice({
    name: 'refundRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        excelOpened: false,
        closeRequetsFormOpened: false,
        assignRequestFormOpened: false,
        errors: null,
        payments: [],
        flights: [],
        bookingDetails: {},
        bookingAmounts: {},
        refundRequest: { histories: [], workflow: [] },
        affectedFlights: [],

    },
    reducers: {
        // Other reducer actions...
        resetState: (state) => {
            state.payments = [];
            state.bookingDetails = {};
            state.flights = [];
            state.refundRequest = { histories: [] };
        },
        toggleExcel: (state, { payload }) => {
            state.excelOpened = payload;
        },
        toggleCloseRequestsFrom: (state, { payload }) => {
            state.closeRequetsFormOpened = payload;
        },
        toggleAssignRequestForm: (state, { payload }) => {
            state.assignRequestFormOpened = payload;
        },
        setAffectedFlights: (state, { payload }) => {
            state.affectedFlights = payload;
        },
        updateRefundsList: (state, action) => {
            state.items = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.refundRequest = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(closeRequets.fulfilled, (state, { payload }) => {
                toast.success('Request closed successfully');
                let items = [...state.items]

                items.forEach((item, i) => {
                    var index = payload.findIndex(p => p.id === item.id)
                    if (index !== -1) {
                        items[i] = { ...items[i], ...payload[index] }
                    }
                })
                state.items = items;
                state.closeRequetsFormOpened = false;
            })
            .addCase(acceptRequets.fulfilled, (state, { payload }) => {
                toast.success('Requests are accepted successfully, Status changed to Payment Initiated');
                state.items = state.items.filter(item => {
                    const matchingPayloadItem = payload.find(p => p.id === item.id);
                    return !(matchingPayloadItem?.state === 'PaymentInitiated');
                });
                state.closeRequestsFormOpened = false;
            })
            .addCase(approvePayments.fulfilled, (state, { payload }) => {
                toast.success('Payment are approved successfully, Status changed to Completed');
                state.items = state.items.filter(item => {
                    const matchingPayloadItem = payload.find(p => p.id === item.id);
                    return !(matchingPayloadItem?.status === 'FullAccepted');
                });
                state.closeRequetsFormOpened = false;
            })
            .addCase(assignRequest.fulfilled, (state, { payload: { workflow } }) => {
                toast.success('Request assigned successfully');
                state.assignRequestFormOpened = false;
                state.refundRequest = {
                    ...state.refundRequest,
                    department: workflow[workflow.length - 1].department,
                    assignee: workflow[workflow.length - 1].assignee,
                    workflow
                };
            })
            .addCase(fetchBookingDetailsByPnr.fulfilled, (state, action) => {
                state.bookingDetails = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchBookingPaymentsByPnr.fulfilled, (state, action) => {
                state.payments = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchPassengerFlightsByPnr.fulfilled, (state, action) => {
                state.flights = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(fetchBookingAmountsByPnr.fulfilled, (state, action) => {
                state.bookingAmounts = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
            .addCase(completeRequest.fulfilled, (state, action) => {
                const { successCallBack } = action.meta.arg;
                toast.success('Request submitted successfully');
                successCallBack && successCallBack(action.payload)
            })
            .addCase(exportToExcel.fulfilled, (state, action) => {
                toast.success('Request Executed Successfully')
                state.excelOpened = false;
            })
            .addCase(exportToExcelFinance.fulfilled, (state, action) => {
                toast.success('Request Executed Successfully')
                state.excelOpened = false;
            })


            .addCase(getDashBoardSummaries.fulfilled, (state, action) => {
                state.statusSummary = action.payload.statusSummary;
                state.reasonSummary = action.payload.reasonSummary;
                state.requestsSummary = action.payload.requestsSummary;
            })


            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => {
                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = true;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;


                    // should be updated 
                    state.loading = true;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    

                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = null;
                    state[`${actionName}_errors`] = null;

                    const getActions = ['fetch', 'get', 'search', 'load'];
                    const containsAction = getActions.some(action => actionName.includes(action));
                
                    const successCallBack = action.meta.arg?.successCallBack;
                    successCallBack && successCallBack(action.payload)

                    // !containsAction && toast.success('Action executed successfully')

                    // should be updated 
                    state.loading = false;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action, payload) => {
                    

                    var actionName = action.type.split("/")[1];
                    state[`${actionName}_loading`] = false;
                    state[`${actionName}_error`] = action.error.message;
                    state[`${actionName}_errors`] = action.payload?.errors;


                    const { failCallBack } = action.meta.arg;

                    // toast.error("Somthing went wronge please try again later...");
                    failCallBack && failCallBack(action.payload?.errors || {})

                    // should be updated 
                    state.loading = false;
                    state.error = action.error.message;
                    state.errors = action.payload?.errors;
                }
            )
    },
});


export const { resetState, toggleExcel, toggleCloseRequestsFrom, toggleAssignRequestForm, setAffectedFlights, updateRefundsList } = requestsSlice.actions
export default requestsSlice.reducer;


