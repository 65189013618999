import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';

export const refundRequestApi = createApi({
    reducerPath: 'refundRequestApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        createRefundRequest: build.mutation({
            query: (values) => ({
                url: '/api/request-refund/csu',
                method: 'POST',
                data: values,
                showToast: true,
            }),
        }),
        processRefundRequests: build.mutation({
            query: (values) => ({
                url: '/api/refund-request/process-requests',
                method: 'POST',
                data: values,
                showToast: true,
            }),
        }),
        fetchAllRefundRequests: build.query({
            query: (params) => ({
                url: '/api/refund/search',
                method: 'POST',
                data: params,
            }),
        }),

        fetchRefundRequestById: build.query({
            query: (id) => ({
                url: `/api/refund-request/details/${id}`,
                method: 'GET',
            }),
        }),

        fetchBookingDetailsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-details/${id}`,
                method: 'GET',
            }),
        }),

        fetchBookingPaymentsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-payments/${id}`,
                method: 'GET',
            }),
        }),

        fetchPassengerFlightsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/passenger-flights/${id}`,
                method: 'GET',
            }),
        }),

        fetchFlightsByRequestIdAndPnr: build.query({
            query: ({requestId, pnr}) => ({
                url: `/api/refund-request/passenger-flights/${pnr}?RefundRequestId=${requestId}`,
                method: 'GET',
            }),
        }),

        fetchBookingAmountsByPnr: build.query({
            query: (id) => ({
                url: `/api/refund-request/booking-amounts/${id}`,
                method: 'GET',
            }),
        }),

        fetchPaymentInitiatedRequests: build.query({
            query: (params) => ({
                url: `/api/refund-request/pending-finance`,
                method: 'POST',
                data: params,
            }),
        }),
        fetchPendingFinanceRequests: build.query({
            query: (params) => ({
                url: `/api/refund-request/pending-finance`,
                method: 'POST',
                data: params,
            }),
        }),

        closeRequests: build.mutation({
            query: (payload) => ({
                url: '/api/request-refund/close-requests',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        processRequest: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/proccess-request',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        addHistory: build.mutation({
            query: (payload) => ({
                url: '/api/refundrequest/addhistory',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),

        exportToExcel: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/export-to-excel',
                method: 'POST',
                data: payload,
                showToast: true,
                successMessage: "Request Executed Successfully, you will receive an email with the excel file",
            }),
        }),

        completeRequest: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/complete',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),
        approvePayments: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/approve-payments',
                method: 'POST',
                data: payload,
                showToast: true,
            }),
        }),
        updateRefundRequest: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request',
                method: 'PUT',
                data: payload,
                showToast: true,
            }),
        }),
        setPaymentResult: build.mutation({
            query: (payload) => ({
                url: '/api/refund-request/payment-result',
                method: 'PUT',
                data: payload,
                showToast: true,
            }),
        }),

        getDashBoardSummaries: build.query({
            query: (payload) => ({
                url: '/api/refund-request/dashboard-summaries',
                method: 'POST',
                data: payload
            }),
        }),

        assignToMe: build.mutation({
            query: (values) => ({
                url: '/api/users/assign/me',
                method: 'POST',
                data: values,
                showToast: false,
            }),
        }),

        assignToUser: build.mutation({
            query: (values) => ({
                url: '/api/users/assign',
                method: 'POST',
                data: values,
                showToast: false,
            }),
        }),

        // Mock endpoint for setting case number - TO BE UPDATED
        setCaseNumber: build.mutation({
            query: (payload) => ({
                url: `/api/refund-request/${payload.id}/case-number`,
                method: 'PUT',
                data: {
                    Id: payload.id,
                    RequestCaseNumber: payload.caseNumber
                },
                showToast: true,
                successMessage: "Case number updated successfully"
            }),
        }),

        // Mock endpoint for sending email - TO BE UPDATED
        sendEmail: build.mutation({
            query: (payload) => ({
                url: `/api/refund-request/${payload.id}/send-missing-bank-info-email`,
                method: 'POST',
                data: payload,
                showToast: true,
                successMessage: "Email sent successfully"
            }),
        }),
    }),
});

export const {
    useCreateRefundRequestMutation,
    useProcessRefundRequestsMutation,
    useUpdateRefundRequestMutation,
    useSetPaymentResultMutation,
    useFetchAllRefundRequestsQuery,
    useFetchPendingFinanceRequestsQuery,
    useFetchPaymentInitiatedRequestsQuery,  
    useFetchRefundRequestByIdQuery,
    useFetchBookingDetailsByPnrQuery,
    useFetchBookingPaymentsByPnrQuery,
    useFetchPassengerFlightsByPnrQuery,
    useFetchFlightsByRequestIdAndPnrQuery,
    useFetchBookingAmountsByPnrQuery,
    useCloseRequestsMutation,
    useProcessRequestMutation,
    useAddHistoryMutation,
    useExportToExcelMutation,
    useCompleteRequestMutation,
    useApprovePaymentsMutation,
    useGetDashBoardSummariesQuery,
    useAssignToMeMutation,
    useAssignToUserMutation,
    useSetCaseNumberMutation,
    useSendEmailMutation
} = refundRequestApi;