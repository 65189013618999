import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';


export const lookupApi = createApi({
    reducerPath: 'lookupApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            getLookups: build.query({
                query: (p) => ({ url: '/api/lookups', params: p, method: 'GET' }),
                keepUnusedDataFor: 300
            }),
            searchUsers: build.mutation({
                query: (keyword) => ({
                    url: '/api/users/search',
                    method: 'POST',
                    data: { keyword }
                })
            })
        };
    },
});

export const { 
    useGetLookupsQuery,
    useSearchUsersMutation
} = lookupApi;
