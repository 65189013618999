import { api} from "@app/layout";
import { useUser} from "@app/layout";

api.defaults.baseURL=process.env.REACT_APP_API_URL;
//this a temp solution to make app use old token from azure AD
api.interceptors.request.use(async function (config) {
    const {getUserToken} = useUser();
    const token =await getUserToken();

    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
});

api.interceptors.request.handlers.reverse(); // to call layout interceptor first before refund app interceptor

api.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;


    if (!expectedError) {
        console.log("Logging unexpected error :::", error);
        // toast.error("un expected error occrured", toastProbs);
    }
    return Promise.reject(error);
});

export default api;
